<template>
  <div style="width: 100%;height: calc(100% - 28px);overflow: hidden;">
    <div style="width: 100%;height: 100%;">
      <div id="search" class="search-container">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-input v-model="searchForm.code" placeholder="代码或名称" clearable @keyup.enter.native="searFavoriteList()">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button @click="searFavoriteList">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div id="table" class="mini-table-container">
        <!-- <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
		@selection-change="handleSelectionChange">-->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" :height="tableHeight" size="mini"
          :header-row-style="{height:'30px'}" :header-cell-style="{background:'#f5f7fa',padding:'0px'}"
          :row-style="{height:'20px'}" :cell-style="{padding:'0px'}" style="width: 100%" border stripe>
          <el-table-column label="序号" align="center" width="50">
            <template slot-scope="scope">{{ (current - 1) * size + scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="code" label="代码" align="center" width="80"></el-table-column>
          <el-table-column prop="name" label="名称" align="center" width="90"></el-table-column>
          <el-table-column prop="tdate" width="120" align="center" label="时间"></el-table-column>
          <el-table-column prop="lastClosePrice" label="昨收盘" align="right" width="80">
            <template slot-scope="scope">
              {{ scope.row.lastClosePrice | rounding}}
            </template>
          </el-table-column>
          <el-table-column prop="openPrice" label="开盘价" align="right" width="80">
            <template slot-scope="scope">
              {{ scope.row.openPrice | rounding}}
            </template>
          </el-table-column>
          <el-table-column prop="closePrice" label="收盘价" align="right" width="80">
            <template slot-scope="scope">
              {{ scope.row.closePrice | rounding}}
            </template>
          </el-table-column>
          <el-table-column prop="minPrice" label="最低价" align="right" width="80">
            <template slot-scope="scope">
              {{ scope.row.minPrice | rounding}}
            </template>
          </el-table-column>
          <el-table-column prop="maxPrice" label="最高价" align="right" width="80">
            <template slot-scope="scope">
              {{ scope.row.maxPrice | rounding}}
            </template>
          </el-table-column>
          <el-table-column prop="zde" label="涨跌额" align="right" width="80">
            <template slot-scope="scope">
              {{ scope.row.zde | rounding}}
            </template>
          </el-table-column>
          <el-table-column prop="zdf" label="涨跌幅%" align="right" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.zdf < 0" style="color: rgb(14, 207, 30);">{{scope.row.zdf | rounding}}</span>
              <span v-else-if="scope.row.zdf >=3" style="color: rgb(248, 36, 36);">{{scope.row.zdf | rounding}}</span>
              <span v-else>{{scope.row.zdf | rounding}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="hsl" label="换手率%" align="right" width="80">
            <template slot-scope="scope">
              {{ scope.row.hsl | rounding}}
            </template>
          </el-table-column>
          <el-table-column prop="cjl" label="成交量万手" align="right" width="100" :formatter="wanFormatter">
          </el-table-column>
          <el-table-column prop="cjje" label="成交额万" align="right" width="110" :formatter="wanFormatter">
          </el-table-column>
          <el-table-column prop="cjbs" label="成交笔数" align="right" width="110"></el-table-column>
          <el-table-column prop="totalValue" label="总市值亿" align="right" width="110" :formatter="yiFormatter">
          </el-table-column>
          <el-table-column prop="circulationValue" label="流通市值亿" align="right" width="110" :formatter="yiFormatter">
          </el-table-column>

          <el-table-column prop="icon" width="70px" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="editHandle(scope.row.id)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper" :page-sizes="[20, 50, 150, 300,500]" :current-page="current"
          :page-size="size" :total="total"></el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Hqnew",
  data() {
    return {
      searchForm: {},
      // delBtlStatu: true,
      total: 0,
      defaultSize: 50,
      defaultCurrent: 1,
      size: this.defaultSize,
      current: this.defaultCurrent,

      tableData: [],
      tableHeight: undefined,
    };
  },
  created() {
    this.tableHeight = window.innerHeight - 60 - 28 - 36 - 32 - 10;
    this.getMyfavorite();
  },

  filters: {
    rounding(value) {
      if (value || value === 0) {
        //这个判断是为了防止数据为空，页面渲染为NAN
        return value.toFixed(2);
      }
    },
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.getMyfavorite();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.getMyfavorite();
    },

    yiFormatter(row, column, cellValue, index) {
      //数值以 亿 为单位  Math.round(7/2)
      return Math.round(cellValue / 100000000);
    },
    wanFormatter(row, column, cellValue, index) {
      //数值以 万 为单位
      return Math.round(cellValue / 10000);
    },

    searFavoriteList() {
      this.current = this.defaultCurrent;
      this.size = this.defaultSize;
      this.getMyfavorite();
    },

    getMyfavorite() {
      let user_id = this.$store.state.userId;
      console.log("==========+++++++===========");
      console.log("user_id : " + user_id);
      this.$axios
        .get("/showwy/myfavorite", {
          params: {
            userId: this.user_id,
            current: this.current,
            size: this.size,
          },
        })
        .then((res) => {
          console.log("==========+++++++===========");
          console.log(res.data.data);
          this.tableData = res.data.data.records;
          this.size = res.data.data.size;
          this.current = res.data.data.current;
          this.total = res.data.data.total;
        });
    },

  },
};
</script>

<style scoped>
.el-pagination {
  float: right;
  margin-top: 5px;
  margin-bottom: 5px;
}

.search-container /deep/ .el-form-item {
  margin-bottom: 2px;
}

.search-container /deep/ .el-input__inner {
  height: 26px;
  line-height: 26px;
}

.search-container /deep/ .el-button {
  padding: 5px 12px;
}

.mini-table-container {
  width: 100%;
  height: calc(100% - 36px);
}
</style>